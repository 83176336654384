import headstreamAxios from "axios";
import { Message } from "element-ui";

// 主请求
const axios = headstreamAxios.create({ timeout: 1000 * 30 });

axios.defaults.baseURL = "/server";
// axios.defaults.baseURL = "http://122.96.151.110:9871";

//请求拦截器
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    tryHideFullScreenLoading();
    if (error.message.includes("timeout")) {
      Message({
        type: "error",
        grouping: true,
        message: "请求超时,请刷新重试或减少时间跨度!",
      });
    } else {
      Message({
        type: "error",
        grouping: true,
        message: "出错了,请刷新后重试:" + error,
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
