import Vue from "vue";
import VueRouter from "vue-router";
let Home = () => import("@/views/Home.vue");
let HealthyManageCore = () => import("@/views/HealthyManageCore");
let SlowSickManageCore = () => import("@/views/SlowSickManageCore");
let HosFollowUp = () => import("@/views/HosFollowUp");
let RegionEdu = () => import("@/views/RegionEdu");
let WholeProcessHealth = () => import("@/views/WholeProcessHealth");
let SmartChronicDisease = () => import("@/views/SmartChronicDisease");
let SmartFollowup = () => import("@/views/SmartFollowup");
let SmartEducation = () => import("@/views/SmartEducation");
let IntelligentPhysical = () => import("@/views/IntelligentPhysical");
let SatisfactionSurvey = () => import("@/views/SatisfactionSurvey.vue");
let ChronicDisease = () => import("@/views/ChronicDisease.vue");

let SmartFamilyDoc = () => import("@/views/SmartFamilyDoc.vue");
let SmartWeapp = () => import("@/views/SmartWeapp.vue");
let SmartDecision = () => import("@/views/SmartDecision.vue");
let SmartInspection = () => import("@/views/SmartInspection.vue");
let SmartLibrary = () => import("@/views/SmartLibrary.vue");

let IntelligentMonitoring = () => import("@/views/IntelligentMonitoring.vue");
let EcologicalRecruitment = () => import("@/views/EcologicalRecruitment.vue");
let aboutUs = () => import("@/views/aboutUs.vue");
let recruit = () => import("@/views/recruit.vue");

//解决路由重复时的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    // name: "Home",
    component: Home,
    redirect: "/Home",
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: "/About",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },

  /** 解决方案模块 */
  //健康管理中心
  {
    path: "/HealthyManageCore",
    name: "HealthyManageCore",
    component: HealthyManageCore,
  },
  //慢病管理中心
  {
    path: "/SlowSickManageCore",
    name: "SlowSickManageCore",
    component: SlowSickManageCore,
  },
  //院内随访
  {
    path: "/HosFollowUp",
    name: "HosFollowUp",
    component: HosFollowUp,
  },
  //区域宣教
  {
    path: "/RegionEdu",
    name: "RegionEdu",
    component: RegionEdu,
  },
  {
    path: "/WholeProcessHealth",
    name: "WholeProcessHealth",
    component: WholeProcessHealth,
  },
  {
    //产品与服务-智慧慢病管理平台
    path: "/SmartChronicDisease",
    name: "SmartChronicDisease",
    component: SmartChronicDisease,
  },
  {
    //产品与服务-智慧随访管理平台
    path: "/SmartFollowup",
    name: "SmartFollowup",
    component: SmartFollowup,
  },
  {
    //产品与服务-智慧宣教管理平台
    path: "/SmartEducation",
    name: "SmartEducation",
    component: SmartEducation,
  },
  {
    //产品与服务-智慧体检系统
    path: "/IntelligentPhysical",
    name: "IntelligentPhysical",
    component: IntelligentPhysical,
  },
  {
    //产品与服务-全程满意度调查
    path: "/SatisfactionSurvey",
    name: "SatisfactionSurvey",
    component: SatisfactionSurvey,
  },
  {
    //产品与服务-慢病管理服务
    path: "/ChronicDisease",
    name: "ChronicDisease",
    component: ChronicDisease,
  },

  {
    //产品与服务-智慧家医工作站
    path: "/SmartFamilyDoc",
    name: "SmartFamilyDoc",
    component: SmartFamilyDoc,
  },
  {
    //产品与服务-智慧慢病医患小程序
    path: "/SmartWeapp",
    name: "SmartWeapp",
    component: SmartWeapp,
  },
  {
    //产品与服务-智慧决策驾驶舱
    path: "/SmartDecision",
    name: "SmartDecision",
    component: SmartDecision,
  },
  {
    //产品与服务-智慧导检系统
    path: "/SmartInspection",
    name: "SmartInspection",
    component: SmartInspection,
  },
  {
    //产品与服务-智慧疾病知识库系统
    path: "/SmartLibrary",
    name: "SmartLibrary",
    component: SmartLibrary,
  },

  {
    path: "/IntelligentMonitoring",
    name: "IntelligentMonitoring",
    component: IntelligentMonitoring,
  },
  {
    path: "/EcologicalRecruitment",
    name: "EcologicalRecruitment",
    component: EcologicalRecruitment,
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    component: aboutUs,
  },
  {
    path: "/Recruit",
    name: "Recruit",
    component: recruit,
  },
  {
    path: "/NewsCenter",
    name: "NewsCenter",
    component: () => import("@/views/NewsCenter"),
  },
];

const router = new VueRouter({
  mode: "history",
  // 修改路由高亮样式，默认值为'router-link-active'
  linkActiveClass: "active",
  routes,
});

export default router;
