<template>
  <div id="app">
    <LinkUs />
    <router-view />
  </div>
</template>
<script>
import LinkUs from "@/components/LinkUs.vue";
export default {
  components: { LinkUs },
  data() {
    return {};
  },
};
</script>
<style>
@keyframes show_app {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#app {
  font-family: PingFang SC, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow-y: auto;
  animation: show_app 1s ease-in forwards;
}

::-webkit-scrollbar {
  width: 8px;
  background: transparent;
  /**/
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-corner {
  background: #179a16;
}
::-webkit-scrollbar {
  height: 0;
  width: 0;
}

@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    /**/
  }
}
.swiper-pagination-bullet-active {
  background: #1aa858 !important;
}
* {
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
}
.flex_wrap {
  flex-wrap: wrap;
}
.d_f_a {
  display: flex;
  align-items: center;
}
.a_i_c {
  align-items: center;
}
.a_i_s {
  align-items: flex-start;
}
.a_i_e {
  align-items: flex-end;
}
.j_c_c,
.j_c {
  justify-content: center;
}
.j_c_s {
  justify-content: flex-start;
}
.j_c_a {
  justify-content: space-around;
}
.j_c_e {
  justify-content: flex-end;
}
.j_c_b,
.j_s {
  justify-content: space-between;
}
.flex_d {
  flex-direction: column;
}
.p_r {
  position: relative;
}
.p_a {
  position: absolute;
}
</style>
