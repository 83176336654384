import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/base.css";
import { Carousel, CarouselItem, Message, Pagination } from "element-ui";
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Pagination.name, Pagination);

Vue.prototype.$message = Message;

import axios from "./api/axios";
import tools from "./api/tools";

let Opt = {
  bind: function (el, binding, vnode) {
    el.style.opacity = "0";
    const io = new IntersectionObserver(
      (entrys) => {
        entrys.forEach((is) => {
          if (!is.isIntersecting) return;
          is.target.style.transform = "translateY(80vh)";
          el.style.opacity = "1";
          setTimeout(() => {
            el.style.transition = "transform 1s";
            is.target.style.transform = "none";
          }, 100);
          io.unobserve(is.target);
        });
      },
      {
        rootMargin: "0px 0px 50px 0px",
      }
    );
    io.observe(el);
  },
  // inserted: function (el, binding, vnode) {},
  // update: function (el, binding, vnode) {},
  // componentUpdated: function (el, binding, vnode) {},
  // unbind: function (el, binding, vnode) {},
};
Vue.directive("runshow", Opt);

function get_opt(csstest) {
  return {
    bind: function (el, binding, vnode) {
      el.style.transform = csstest;
      const io = new IntersectionObserver(
        (entrys) => {
          entrys.forEach((is) => {
            if (!is.isIntersecting) return;
            setTimeout(() => {
              el.style.transition = "transform 1.2s ease";
              is.target.style.transform = "none";
            }, 100);
            io.unobserve(is.target);
          });
        },
        {
          rootMargin: "0px 0px 50px 0px",
        }
      );
      io.observe(el);
    },
  };
}
Vue.directive("runleftshow", get_opt("translateX(-100%)"));
Vue.directive("runrightshow", get_opt("translateX(100%)"));
Vue.directive("runcentershow", get_opt("scale(.5)"));
Vue.directive("runtopshow", get_opt("translateY(-80px)"));
Vue.directive("runbannershow", get_opt("scale(1.6)"));
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$tools = tools;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
