import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showAppointModal: false,
  },
  mutations: {
    changeAppointModal(state, flag) {
      state.showAppointModal = flag;
    },
  },
  actions: {},
  modules: {},
});
