<template>
  <div class="LinkUs" v-show="showLinkUs">
    <!-- <div class="menu">
      <div class="title">
        <p>
          <i class="iconfont icon-weixin"></i>
        </p>
        <p>微信</p>
      </div>

      <div class="contView"></div>
    </div> -->
    <div class="menu">
      <div class="title">
        <p><i class="iconfont icon-shouhuodizhi"></i></p>
        <p>地址</p>
      </div>

      <div class="contView">
        南京市建邺区泰山路 159 号 <br />
        正太中心大厦 B 座 1001 室
      </div>
    </div>
    <div class="menu">
      <div class="title">
        <p><i class="iconfont icon-24gl-telephone"></i></p>
        <p>联系我们</p>
      </div>

      <div class="contView">施女士：18810905799</div>
    </div>
    <div
      class="menu"
      style="cursor: pointer"
      @click="$store.commit('changeAppointModal', true)"
    >
      <div class="title">
        <p><i class="iconfont icon-liuyan"></i></p>
        <p>留言</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLinkUs: false,
    };
  },
  computed: {
    showAppointModal() {
      return this.$store.state.showAppointModal;
    },
  },
  mounted() {
    // let that = this;
    // // console.log("header init");
    // document.getElementById("app").addEventListener("scroll", function (e) {
    //   console.log(e.target.scrollTop);
    //   if (e.target.scrollTop > 80) {
    //     that.setLinkUsFlag(true);
    //   } else {
    //     that.setLinkUsFlag(false);
    //   }
    // });
  },
  methods: {
    setLinkUsFlag(type) {
      this.showLinkUs = type;
    },
  },
};
</script>

<style scoped lang="scss">
.LinkUs {
  position: fixed;
  right: 0px;
  bottom: 12px;
  width: 76px;
  background-color: #f7f9fa;
  z-index: 10;
  border-radius: 12px 0 0 12px;
  border: 1px solid #e6e7e8;
  // box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
  .menu {
    padding: 6px 0;
    position: relative;
    .title {
      & > p {
        line-height: 1.6;
        text-align: center;
      }
      .iconfont {
        font-size: 24px;
      }
    }

    .contView {
      border-radius: 8px 0 0 8px;
      padding: 8px;
      white-space: nowrap;
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: fit-content;
      background-color: #f7f9fa;
      border: 1px solid #e6e7e8;
      line-height: 1.6;
      // box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
      z-index: 5;
      right: 76px;
    }
  }
  .menu:hover {
    & > .contView {
      width: fit-content;
      transition: all 1s;
      display: block;
    }
  }
}
@media screen and (max-width: 1024px) {
  .LinkUs {
    display: none;
  }
}
</style>
