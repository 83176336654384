const tools = {
  transformTime: (timestamp) => {
    let a = new Date(timestamp).getTime();
    const date = new Date(a);
    const Y = date.getFullYear() + "-";
    const M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    const D =
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    const h =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    const m =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const dateString = Y + M + D + h + m;
    return dateString;
  },
};
export default tools;
